export default function KickoffTitle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="10"
      viewBox="0 0 72 10"
      fill="none">
      <path
        d="M25.6594 8.80717C25.6983 8.64016 25.6076 8.49095 25.4262 8.19254L25.3507 8.06847C25.1131 7.67765 24.9943 7.48224 24.8392 7.43611C24.6841 7.38998 24.4169 7.51787 23.8823 7.77364C23.8452 7.79141 23.8076 7.8085 23.7694 7.82492C23.3833 7.98653 22.9793 8.06734 22.5573 8.06734C22.1308 8.06734 21.7312 7.98653 21.3586 7.82492C20.986 7.6633 20.6605 7.44332 20.3822 7.16498C20.1039 6.88664 19.8839 6.56341 19.7223 6.19529C19.5606 5.82267 19.4798 5.42536 19.4798 5.00337C19.4798 4.58137 19.5606 4.18406 19.7223 3.81145C19.8839 3.43434 20.1039 3.10887 20.3822 2.83502C20.6605 2.55668 20.986 2.3367 21.3586 2.17508C21.7312 2.01347 22.1308 1.93266 22.5573 1.93266C22.9793 1.93266 23.3833 2.01571 23.7694 2.18182C23.8066 2.19741 23.8434 2.21366 23.8797 2.23059C24.419 2.48218 24.6886 2.60797 24.8423 2.56142C24.9961 2.51488 25.1143 2.32043 25.3507 1.93153L25.4283 1.80394C25.6089 1.50692 25.6992 1.35841 25.6606 1.19166C25.622 1.0249 25.4913 0.94131 25.2301 0.774125C24.9353 0.58553 24.6213 0.430743 24.2879 0.309764C23.7312 0.103255 23.1544 0 22.5573 0C22.0994 0 21.6572 0.0606061 21.2307 0.181818C20.8087 0.298541 20.4114 0.466891 20.0388 0.686869C19.6706 0.902357 19.3339 1.16274 19.0287 1.46801C18.7234 1.77329 18.4608 2.11223 18.2408 2.48485C18.0253 2.85297 17.8569 3.25028 17.7357 3.67677C17.619 4.10325 17.5606 4.54545 17.5606 5.00337C17.5606 5.46128 17.619 5.90348 17.7357 6.32997C17.8569 6.75196 18.0253 7.14927 18.2408 7.52189C18.4608 7.89001 18.7234 8.22671 19.0287 8.53199C19.3339 8.83726 19.6706 9.09989 20.0388 9.31987C20.4114 9.53535 20.8087 9.70146 21.2307 9.81818C21.6572 9.93939 22.0994 10 22.5573 10C23.1544 10 23.7312 9.89674 24.2879 9.69024C24.6193 9.56729 24.9317 9.41251 25.2249 9.22591C25.4886 9.05809 25.6205 8.97417 25.6594 8.80717Z"
        fill="#959CB0"
      />
      <path
        d="M2.00136 9.15984C2.00136 9.55586 2.00136 9.75387 1.87834 9.8769C1.75531 9.99993 1.5573 9.99993 1.16128 9.99993H0.840089C0.444067 9.99993 0.246056 9.99993 0.123028 9.8769C0 9.75387 0 9.55586 0 9.15984V0.840166C0 0.444144 0 0.246133 0.123028 0.123105C0.246056 7.69124e-05 0.444067 7.69124e-05 0.840089 7.69124e-05H1.16128C1.5573 7.69124e-05 1.75531 7.69124e-05 1.87834 0.123105C2.00136 0.246133 2.00136 0.444144 2.00136 0.840166V3.2259C2.00136 3.9588 2.00136 4.32525 2.20288 4.39205C2.4044 4.45885 2.62329 4.16495 3.06106 3.57715L5.37243 0.473688C5.54549 0.241314 5.63202 0.125128 5.75655 0.0626023C5.88108 7.69124e-05 6.02595 7.69124e-05 6.31569 7.69124e-05H6.82353C7.33627 7.69124e-05 7.59265 7.69124e-05 7.67394 0.162509C7.75524 0.324941 7.60158 0.53016 7.29426 0.940599L5.81076 2.92187C5.33911 3.55177 5.10329 3.86672 5.07692 4.23594C5.05055 4.60515 5.23922 4.95041 5.61656 5.64095L7.52305 9.12988C7.73506 9.51786 7.84107 9.71186 7.75564 9.85589C7.67021 9.99993 7.44914 9.99993 7.00701 9.99993H6.42479C6.08379 9.99993 5.91329 9.99993 5.77433 9.91726C5.63537 9.8346 5.554 9.68478 5.39125 9.38512L3.9667 6.76212C3.76947 6.39897 3.67085 6.2174 3.51043 6.2058C3.35001 6.19421 3.22631 6.35971 2.9789 6.69072L2.53638 7.28277C2.27121 7.63754 2.13862 7.81492 2.06999 8.02137C2.00136 8.22783 2.00136 8.44929 2.00136 8.89221V9.15984Z"
        fill="#959CB0"
      />
      <path
        d="M13.7802 9.15984C13.7802 9.55586 13.7802 9.75387 13.6572 9.8769C13.5342 9.99993 13.3362 9.99993 12.9402 9.99993H12.619C12.223 9.99993 12.0249 9.99993 11.9019 9.8769C11.7789 9.75387 11.7789 9.55586 11.7789 9.15984V0.840166C11.7789 0.444144 11.7789 0.246133 11.9019 0.123105C12.0249 7.69124e-05 12.223 7.69124e-05 12.619 7.69124e-05H12.9402C13.3362 7.69124e-05 13.5342 7.69124e-05 13.6572 0.123105C13.7802 0.246133 13.7802 0.444144 13.7802 0.840166V9.15984Z"
        fill="#959CB0"
      />
      <path
        d="M31.5886 9.15984C31.5886 9.55586 31.5886 9.75387 31.4656 9.8769C31.3425 9.99993 31.1445 9.99993 30.7485 9.99993H30.4168C30.0208 9.99993 29.8228 9.99993 29.6997 9.8769C29.5767 9.75387 29.5767 9.55586 29.5767 9.15984V0.840166C29.5767 0.444144 29.5767 0.246133 29.6997 0.123105C29.8228 7.69124e-05 30.0208 7.69124e-05 30.4168 7.69124e-05H30.7485C31.1445 7.69124e-05 31.3425 7.69124e-05 31.4656 0.123105C31.5886 0.246133 31.5886 0.444144 31.5886 0.840166V3.23333C31.5886 3.96357 31.5886 4.32868 31.7898 4.39565C31.9909 4.46261 32.2097 4.17033 32.6474 3.58577L34.9791 0.471322C35.1522 0.2401 35.2388 0.124464 35.3631 0.0622714C35.4873 7.69124e-05 35.6318 7.69124e-05 35.9206 7.69124e-05H36.4385C36.9527 7.69124e-05 37.2098 7.69124e-05 37.291 0.16284C37.3722 0.325602 37.2176 0.530997 36.9084 0.941786L35.4212 2.91759C34.9456 3.54941 34.7079 3.86532 34.6813 4.23613C34.6548 4.60695 34.8451 4.95351 35.2259 5.64661L37.1386 9.12874C37.352 9.51711 37.4587 9.7113 37.3733 9.85561C37.2879 9.99993 37.0663 9.99993 36.6232 9.99993H36.0298C35.6896 9.99993 35.5195 9.99993 35.3808 9.91759C35.242 9.83527 35.1605 9.68597 34.9975 9.38739L33.5614 6.75699C33.3643 6.39593 33.2658 6.2154 33.1058 6.20381C32.9458 6.19222 32.8223 6.35666 32.5751 6.68554L32.1277 7.28111C31.8605 7.63668 31.7269 7.81446 31.6578 8.02165C31.5886 8.22884 31.5886 8.45121 31.5886 8.89596V9.15984Z"
        fill="#959CB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.2074 6.32502C50.3288 5.89795 50.3895 5.45516 50.3895 4.99663C50.3895 4.5381 50.3288 4.0953 50.2074 3.66824C50.0905 3.24118 49.9242 2.84334 49.7084 2.47471C49.4927 2.10609 49.2319 1.76894 48.9262 1.46325C48.6206 1.15756 48.2834 0.896831 47.9148 0.681052C47.5462 0.465273 47.1483 0.298944 46.7213 0.182063C46.2942 0.0606878 45.8514 0 45.3929 0C44.9343 0 44.4915 0.0606878 44.0645 0.182063C43.6419 0.298944 43.2441 0.465273 42.871 0.681052C42.5023 0.896831 42.1652 1.15756 41.8595 1.46325C41.5538 1.76894 41.2908 2.10609 41.0705 2.47471C40.8548 2.84334 40.6862 3.24118 40.5648 3.66824C40.4479 4.0953 40.3895 4.5381 40.3895 4.99663C40.3895 5.45516 40.4479 5.89795 40.5648 6.32502C40.6862 6.74758 40.8548 7.14543 41.0705 7.51854C41.2908 7.88717 41.5538 8.22432 41.8595 8.53001C42.1652 8.83569 42.5023 9.09867 42.871 9.31895C43.2441 9.53473 43.6419 9.70106 44.0645 9.81794C44.4915 9.93931 44.9343 10 45.3929 10C45.8514 10 46.2942 9.93931 46.7213 9.81794C47.1483 9.70106 47.5462 9.53473 47.9148 9.31895C48.2834 9.09867 48.6206 8.83569 48.9262 8.53001C49.2319 8.22432 49.4927 7.88717 49.7084 7.51854C49.9242 7.14543 50.0905 6.74758 50.2074 6.32502ZM48.225 3.8031C48.3868 4.17622 48.4677 4.57406 48.4677 4.99663C48.4677 5.4192 48.3868 5.81704 48.225 6.19015C48.0631 6.55878 47.8429 6.88245 47.5641 7.16116C47.2899 7.43987 46.964 7.66015 46.5864 7.82198C46.2133 7.98382 45.8154 8.06473 45.3929 8.06473C44.9658 8.06473 44.5657 7.98382 44.1926 7.82198C43.8195 7.66015 43.4936 7.43987 43.2148 7.16116C42.9361 6.88245 42.7159 6.55878 42.554 6.19015C42.3922 5.81704 42.3113 5.4192 42.3113 4.99663C42.3113 4.57406 42.3922 4.17622 42.554 3.8031C42.7159 3.42549 42.9361 3.09957 43.2148 2.82535C43.4936 2.54664 43.8195 2.32637 44.1926 2.16453C44.5657 2.0027 44.9658 1.92178 45.3929 1.92178C45.8154 1.92178 46.2133 2.0027 46.5864 2.16453C46.964 2.32637 47.2899 2.54664 47.5641 2.82535C47.8429 3.09957 48.0631 3.42549 48.225 3.8031Z"
        fill="#959CB0"
      />
      <path
        d="M56.1713 9.15984C56.1713 9.55586 56.1713 9.75387 56.0482 9.8769C55.9252 9.99993 55.7272 9.99993 55.3312 9.99993H55.01C54.614 9.99993 54.416 9.99993 54.2929 9.8769C54.1699 9.75387 54.1699 9.55586 54.1699 9.15984V1.1762C54.1699 0.621771 54.1699 0.344556 54.3421 0.172316C54.5144 7.69124e-05 54.7916 7.69124e-05 55.346 7.69124e-05H60.1631C60.5591 7.69124e-05 60.7571 7.69124e-05 60.8801 0.123105C61.0032 0.246133 61.0032 0.444144 61.0032 0.840166V1.16135C61.0032 1.55737 61.0032 1.75538 60.8801 1.87841C60.7571 2.00144 60.5591 2.00144 60.1631 2.00144H57.0114C56.6153 2.00144 56.4173 2.00144 56.2943 2.12447C56.1713 2.2475 56.1713 2.44551 56.1713 2.84153V3.16272C56.1713 3.55874 56.1713 3.75675 56.2943 3.87978C56.4173 4.00281 56.6153 4.00281 57.0114 4.00281H58.1617C58.5577 4.00281 58.7558 4.00281 58.8788 4.12583C59.0018 4.24886 59.0018 4.44687 59.0018 4.84289V5.16408C59.0018 5.5601 59.0018 5.75811 58.8788 5.88114C58.7558 6.00417 58.5577 6.00417 58.1617 6.00417H57.0114C56.6153 6.00417 56.4173 6.00417 56.2943 6.1272C56.1713 6.25023 56.1713 6.44824 56.1713 6.84426V9.15984Z"
        fill="#959CB0"
      />
      <path
        d="M66.6619 9.8769C66.7849 9.75387 66.7849 9.55586 66.7849 9.15984V6.84426C66.7849 6.44824 66.7849 6.25023 66.908 6.1272C67.031 6.00417 67.229 6.00417 67.625 6.00417H68.7754C69.1714 6.00417 69.3694 6.00417 69.4925 5.88114C69.6155 5.75811 69.6155 5.5601 69.6155 5.16408V4.84289C69.6155 4.44687 69.6155 4.24886 69.4925 4.12583C69.3694 4.00281 69.1714 4.00281 68.7754 4.00281H67.625C67.229 4.00281 67.031 4.00281 66.908 3.87978C66.7849 3.75675 66.7849 3.55874 66.7849 3.16272V2.84153C66.7849 2.44551 66.7849 2.2475 66.908 2.12447C67.031 2.00144 67.229 2.00144 67.625 2.00144H70.7768C71.1728 2.00144 71.3708 2.00144 71.4938 1.87841C71.6169 1.75538 71.6169 1.55737 71.6169 1.16135V0.840166C71.6169 0.444144 71.6169 0.246133 71.4938 0.123105C71.3708 7.69124e-05 71.1728 7.69124e-05 70.7768 7.69124e-05H65.9597C65.4053 7.69124e-05 65.1281 7.69124e-05 64.9558 0.172316C64.7836 0.344556 64.7836 0.621771 64.7836 1.1762V9.15984C64.7836 9.55586 64.7836 9.75387 64.9066 9.8769C65.0296 9.99993 65.2276 9.99993 65.6237 9.99993H65.9449C66.3409 9.99993 66.5389 9.99993 66.6619 9.8769Z"
        fill="#959CB0"
      />
    </svg>
  )
}
