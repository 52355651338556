import cx from 'classnames'
import s from './style.module.css'

export default function SelectionDot({ selected }: { selected: boolean }) {
  return (
    <div className={s.selectionCircle}>
      <div className={cx(s.notSelected, { [s.selected]: selected })}></div>
    </div>
  )
}
