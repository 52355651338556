import Image from 'next/image'
import s from './styles.module.css'
import {
  NUTRITION_ONLY_PRODUCT_ID,
  WORKOUT_AND_NUTRITION_PRODUCT_ID,
} from 'components/payment/common/constants'
import cloudinary from 'clients/cloudinary'

function getImageUrl(publicId: string) {
  return cloudinary.image(publicId).toURL()
}

export default function PlanCardImage({ product }: { product: any }) {
  if (product.id === WORKOUT_AND_NUTRITION_PRODUCT_ID) {
    return (
      <>
        <Image
          src={getImageUrl('static/food_yantpf')}
          alt={product.name}
          width={44}
          height={44}
          className={s.planCardRotatedLeftImage}
        />
        <Image
          src={getImageUrl('static/pushup_zvoujz')}
          alt={product.name}
          width={44}
          height={44}
          className={s.planCardRotatedRightImage}
        />
      </>
    )
  }

  const imageUrl =
    product.id === NUTRITION_ONLY_PRODUCT_ID
      ? getImageUrl('static/food_yantpf')
      : getImageUrl('static/pushup_zvoujz')

  return (
    <Image
      src={imageUrl}
      alt={product.name}
      width={44}
      height={44}
      className={s.planCardImageOne}
    />
  )
}
