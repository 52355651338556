import cx from 'classnames'
import KickoffTitle from './kickoff-title'
import s from './styles.module.css'
import SelectionDot from 'components/payment/common/selection-dot'
import BulletPoints from '../../common/bullet-points'
import PlanCardImage from './plan-card-image'
import { getDailyPrice, getPriceInDollars } from 'utilities/stripe'
import { AnalyticsSection } from 'containers/analytics'
import { TrackedButton } from 'components/shared/analytics'
import { BILLING_FREQUENCY_TEXT_BY_BILLING_PERIOD_IN_MONTHS } from 'constants/products'

const billingFrequencyAbbreviation = {
  monthly: 'mo',
  yearly: 'yr',
}

interface IProductSelectionCardProps {
  product: any
  coachFirstName?: string
  selected?: boolean
  isPaidTrial?: boolean
  isGuestPass?: boolean
  onClick?: () => void
  billingPeriodInMonths?: number
}

export const GROUP_TO_PRODUCT_TITLE = {
  workoutAndNutrition: 'Personal Training & Nutrition Coaching',
  nutrition: 'Nutrition Coaching',
  workout: 'Personal Training',
}

export default function ProductSelectionCard({
  product,
  coachFirstName = 'your coach',
  selected,
  onClick,
  billingPeriodInMonths = 1,
  isPaidTrial,
  isGuestPass,
}: IProductSelectionCardProps) {
  const price = product.prices.find(
    p => p.billingPeriodInMonths === billingPeriodInMonths
  )
  const [priceInDollars] = getPriceInDollars({
    price,
  })

  const [dailyPriceInDollars] = getDailyPrice({
    price,
    roundTo: 10,
  })

  const billingFrequencyText =
    BILLING_FREQUENCY_TEXT_BY_BILLING_PERIOD_IN_MONTHS[billingPeriodInMonths]

  const productTitle = GROUP_TO_PRODUCT_TITLE[product.group]

  return (
    <AnalyticsSection
      name="Plan Card"
      properties={{
        productId: product.id,
        productName: product.descriptiveName,
      }}>
      <TrackedButton
        name={product.descriptiveName}
        className={cx(s.planCardContainer, { [s.selected]: selected })}
        onClick={onClick}>
        {product.isRecommended && (
          <div className={s.recommendedTag}>
            <span className={s.recommendedTagText}>Recommended</span>
          </div>
        )}
        <div className={s.planCardHeader}>
          <div className={s.planCardImageWrapper}>
            <PlanCardImage product={product} />
          </div>
          <div className={s.planCardTitleWrapper}>
            <KickoffTitle />
            <span className={s.planCardTitle}>{productTitle}</span>
          </div>
          <div className={s.planCardSelectionDot}>
            <SelectionDot selected={selected} />
          </div>
        </div>
        <div>
          <BulletPoints
            productId={product.id}
            coachFirstName={coachFirstName}
            showLiveDescription
          />
        </div>
        <div className={s.planCardPriceWrapper}>
          {isPaidTrial || isGuestPass ? (
            <div>
              <div>
                <span className={s.planCardDailyPrice}>
                  {isGuestPass ? `$0` : `$20`}
                </span>
                <span className={s.planCardDailyPriceText}>
                  {isGuestPass ? ` for 1 month` : ` for 7 days`}
                </span>
              </div>
              <div>
                <span className={s.planCardAfterTrialMonthlyPrice}>
                  {`After${isGuestPass ? ': ' : ' Trial: '}`}
                  {`$${dailyPriceInDollars}/day ($${priceInDollars}/${billingFrequencyAbbreviation[billingFrequencyText]})`}
                </span>
              </div>
            </div>
          ) : (
            <>
              <div>
                <span
                  className={
                    s.planCardDailyPrice
                  }>{`$${dailyPriceInDollars}`}</span>
                <span className={s.planCardDailyPriceText}>/Day</span>
              </div>
              <div
                className={
                  s.planCardMonlthlyPrice
                }>{`$${priceInDollars} Billed ${billingFrequencyText}`}</div>
            </>
          )}
        </div>
      </TrackedButton>
    </AnalyticsSection>
  )
}
